import FontProps from "../props/fonts";

export const FontList = [
  "Poppins",
  "Inter",
  "Montserrat",
  "Rubik",
  "Manrope",
  "Roboto",
];

export const fontFamily: FontProps = {
  Montserrat: "font-Montserrat",
  Poppins: "font-Poppins",
  Rubik: "font-Rubik",
  Manrope: "font-Manrope",
  Roboto: "font-Roboto",
  Inter: "font-Inter",
};
