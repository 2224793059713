import React, { useState } from "react";
import { useFont } from "../customHooks/useFont";
import { useResumeData } from "../customHooks/useResumeData";
import { useTheme } from "../customHooks/useTheme";
import { getResumeData } from "../redux/reducer";

const BasicDetails = () => {
  const [theme] = useTheme();
  const [font] = useFont();
  const [inputPosition, setInputPosition] = useState("");
  const [resumeData, setResumeData] = useResumeData();

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputPlace: string
  ) => {
    setResumeData({ ...resumeData, [inputPlace]: event.target.value });
  };

  const onBlurHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputPlace: string,
    defaultData: string
  ) => {
    if (event.target.value === "") {
      setResumeData({
        ...resumeData,
        [inputPlace]: [defaultData],
      });
    }
    setInputPosition("");
  };

  return (
    <>
      <div className={`flex flex-wrap justify-between p-5 ${font}`}>
        <div className="w-full md:w-5/12 ">
          {inputPosition === "isedit-name" ? (
            <input
              autoFocus
              className={`${theme.primary_fg()} font-bold text-2xl outline-none bg-transparent w-full h-8`}
              value={resumeData.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChangeHandler(event, "name")
              }
              onBlur={(event) =>
                onBlurHandler(event, "name", getResumeData.name)
              }
            />
          ) : (
            <div
              className={`${theme.primary_fg()} font-bold text-2xl`}
              onClick={() => setInputPosition("isedit-name")}
            >
              {resumeData.name}
            </div>
          )}
          {inputPosition === "isedit-position" ? (
            <input
              autoFocus
              className="text-gray-400  text-base outline-none bg-transparent"
              value={resumeData.position}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChangeHandler(event, "position")
              }
              onBlur={(event) =>
                onBlurHandler(event, "position", getResumeData.position)
              }
            />
          ) : (
            <div
              className="text-gray-400  text-base "
              onClick={() => setInputPosition("isedit-position")}
            >
              {resumeData.position}
            </div>
          )}

          {inputPosition === "isedit-description" ? (
            <textarea
              autoFocus
              rows={4}
              className="my-2 overflow-hidden  text-ellipsis text-sm w-full outline-none bg-transparent"
              value={resumeData.description}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setResumeData({
                  ...resumeData,
                  description: event.target.value,
                });
              }}
              onBlur={(event) => {
                if (event.target.value === "") {
                  setResumeData({
                    ...resumeData,
                    description: getResumeData.description,
                  });
                }
                setInputPosition("");
              }}
            >
              {resumeData.description}
            </textarea>
          ) : (
            <div
              className={`mt-3 overflow-hidden h-20 text-ellipsis text-sm`}
              onClick={() => setInputPosition("isedit-description")}
            >
              {resumeData.description}
            </div>
          )}
        </div>
        <div className="w-12/12 mt-3 md:w-6/12 md:mt-0  ">
          <div className="flex flex-row mb-3 items-center w-full md:flex-row-reverse">
            <div className="mr-2 md:ml-2">
              <i className={`fa fa-envelope ${theme.primary_fg()} text-sm`} />
            </div>
            {inputPosition === "isedit-email" ? (
              <input
                autoFocus
                className="text-sm font-medium outline-none text-right"
                value={resumeData.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeHandler(event, "email")
                }
                onBlur={(event) =>
                  onBlurHandler(event, "email", getResumeData.email)
                }
              />
            ) : (
              <div
                className="text-sm font-medium"
                onClick={() => setInputPosition("isedit-email")}
              >
                {resumeData.email}
              </div>
            )}
          </div>
          <div className="flex flex-row mb-3 items-center md:flex-row-reverse">
            <div className="mr-2 md:ml-2">
              <i
                className={`fa-solid fa-mobile-screen-button  ${theme.primary_fg()}`}
              />
            </div>
            {inputPosition === "isedit-ph_no" ? (
              <input
                autoFocus
                className="text-sm font-medium outline-none text-right"
                value={resumeData.ph_no}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeHandler(event, "ph_no")
                }
                onBlur={(event) =>
                  onBlurHandler(event, "ph_no", getResumeData.ph_no)
                }
              />
            ) : (
              <div
                className="text-sm font-medium"
                onClick={() => setInputPosition("isedit-ph_no")}
              >
                {resumeData.ph_no}
              </div>
            )}
            {/* <div className="text-sm font-medium">{resumeData.ph_no}</div> */}
          </div>
          <div className="flex flex-row items-center mb-3 md:flex-row-reverse">
            <div className="mr-2 md:ml-2">
              <i className={`fa-solid fa-location-dot ${theme.primary_fg()}`} />
            </div>
            {inputPosition === "isedit-location" ? (
              <input
                autoFocus
                className="text-sm font-medium outline-none text-right"
                value={resumeData.location}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeHandler(event, "location")
                }
                onBlur={(event) =>
                  onBlurHandler(event, "location", getResumeData.location)
                }
              />
            ) : (
              <div
                className="text-sm font-medium"
                onClick={() => setInputPosition("isedit-location")}
              >
                {resumeData.location}
              </div>
            )}

            {/* <div className="text-sm font-medium">{resumeData.location}</div> */}
          </div>
          <div className="flex flex-row items-center mb-3 md:flex-row-reverse">
            <div className="mr-2 md:ml-2">
              <i className={`fa-brands fa-linkedin ${theme.primary_fg()} `} />
            </div>
            {inputPosition === "isedit-linkedin" ? (
              <input
                autoFocus
                className="text-sm font-medium outline-none text-right"
                value={resumeData.linkedin}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeHandler(event, "linkedin")
                }
                onBlur={(event) =>
                  onBlurHandler(event, "linkedin", getResumeData.linkedin)
                }
              />
            ) : (
              <div
                className="text-sm font-medium"
                onClick={() => setInputPosition("isedit-linkedin")}
              >
                {resumeData.linkedin}
              </div>
            )}
            {/* <div className="text-sm font-medium">{resumeData.linkedin}</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
