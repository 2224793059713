import BasicDetails from "../components/BasicDetails";
import "../App.css";
import ThemeFontChange from "../components/ThemeFontChange";
import LeftComponents from "../components/LeftComponents";
import RightComponents from "../components/RightComponents";
import DownloadJSON from "../components/DownloadJSON";

//let themes = ["red", "orange", "blue"];

const Resume = () => {
  //console.log(themeChangeColor("red").primary_fg());

  return (
    <>
      <div className="mt-8 font-bold text-center text-3xl ">Resume Builder</div>

      <div className="m-14 p-3 border-2 border-gray-300 lg:mx-56 ">
        <BasicDetails />
        <div className="mt-1 lg:mt-10 mx-4 flex flex-row flex-wrap justify-between">
          <LeftComponents />
          <RightComponents />
        </div>
      </div>

      <ThemeFontChange />
      <DownloadJSON />
    </>
  );
};

export default Resume;
