import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontChange from "../configs/fontChange";
import { updateFont } from "../redux/actions";

export const useFont = (): any => {
  const dispatch = useDispatch();
  const getFont = useSelector((state: any) => state.getFont);

  const [font, setFont] = useState(getFont);

  useEffect(() => {
    dispatch(updateFont(font));
  }, [dispatch, font]);

  return [fontChange(getFont), setFont];
};
