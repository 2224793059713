import { legacy_createStore as createStore } from "redux";
import reducer from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["getData"],
  transforms: [
    {
      in: (state: any) => ({
        ...state,
        getData: { ...state.getData, lsLayout: [] },
      }),
      out: (state: any) => ({
        ...state,
        getData: { ...state.getData, lsLayout: [] },
      }),
    },
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, composeWithDevTools());
const persistor = persistStore(store);

export { store, persistor };
