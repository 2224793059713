import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Downloaded from "./screens/Downloaded";

import Resume from "./screens/Resume";

export default function RouterTemplate() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Resume />} />
        <Route path="download" element={<Downloaded />} />
      </Routes>
    </BrowserRouter>
  );
}
