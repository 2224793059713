import { useState } from "react";
import { useFont } from "../customHooks/useFont";
import { useResumeData } from "../customHooks/useResumeData";
import { useTheme } from "../customHooks/useTheme";

const RightComponents = () => {
  const [resumeData, setResumeData] = useResumeData();
  const [inputPosition, setInputPosition] = useState("");
  // const getData = useSelector((state: any) => state.getData);
  //console.log(getData.lsLayout);
  const [theme] = useTheme();
  const [font] = useFont();

  const addSkill = (i: number) => {
    return `Skill ${i + 1}`;
  };
  const addOrg = (i: number) => {
    return `Organization ${i + 1}`;
  };

  const skillsComponent = (skillData: any, rsIndex: any) => {
    if (skillData.type === "skill") {
      return (
        <div className="w-full">
          {inputPosition === "isedit-skills" ? (
            <div className="flex flex-row items-center ">
              <input
                autoFocus
                className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black outline-none w-20`}
                value={resumeData.rsLayout[rsIndex].title.toUpperCase()}
                onChange={(event: any) => {
                  resumeData.rsLayout[rsIndex].title = event.target.value;
                  setResumeData({
                    ...resumeData,
                  });
                }}
                onBlur={(event: any) => {
                  if (event.target.value.trim() === "") {
                    resumeData.rsLayout[rsIndex].title = "Skills";
                    // getData.rsLayout[rsIndex].title;
                    setResumeData({
                      ...resumeData,
                    });
                  }
                }}
              />
              {inputPosition === "isedit-skills" && (
                <div
                  onClick={() => {
                    resumeData.rsLayout[rsIndex].record.skills.push(
                      addSkill(
                        resumeData.rsLayout[rsIndex].record.skills.length
                      )
                    );
                    // console.log(resumeData.rsLayout[rsIndex].record);
                    setResumeData({ ...resumeData });
                  }}
                >
                  <i
                    className={` ml-8 fa-solid fa-circle-plus cursor-pointer text-xl ${theme.primary_fg()}`}
                  ></i>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-row items-center ">
              <div
                className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black`}
                onClick={(event) => {
                  event.stopPropagation();
                  // console.log("hello");
                  setInputPosition("isedit-skills");
                }}
              >
                {skillData.title.toUpperCase()}
              </div>
            </div>
          )}
          <div className="flex flex-wrap mb-8 mt-5">
            {skillData.record.skills.map((skill: any, index: any) => {
              return inputPosition === "isedit-skills" + index ? (
                <div className={` flex rounded-md`}>
                  <input
                    autoFocus
                    className={`mr-5 px-2 py-1 w-16 ${theme.primary_fg()} rounded-md border border-gray-300 outline-none `}
                    value={resumeData.rsLayout[rsIndex].record.skills[index]}
                    onChange={(event: any) => {
                      resumeData.rsLayout[rsIndex].record.skills[index] =
                        event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event: any) => {
                      if (event.target.value.trim() === "") {
                        resumeData.rsLayout[rsIndex].record.skills[index] =
                          "Skill";
                        // getData.rsLayout[rsIndex].record.skills[index];
                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                  />
                  {inputPosition === "isedit-skills" + index && index !== 0 && (
                    <div
                      className={`absolute flex ${theme.bg()} -ml-3 -mt-2  w-5 h-5 rounded-full items-center justify-center cursor-pointer`}
                      onClick={(event) => {
                        event.stopPropagation();
                        resumeData.rsLayout[rsIndex].record.skills!.splice(
                          index,
                          1
                        );
                        setResumeData({ ...resumeData });
                      }}
                    >
                      <i className={`fa fa-times ${theme.secondary_fg()}`} />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`mr-5 px-3 py-1 mb-2 ${theme.bg()} ${theme.secondary_fg()} rounded-md `}
                  onClick={(event) => {
                    event.stopPropagation();
                    setInputPosition("isedit-skills" + index);
                  }}
                >
                  {skill}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  const orgComponent = (orgData: any, rsIndex: any) => {
    if (orgData.type === "org") {
      return (
        <div className="w-full">
          {inputPosition === "isedit-org" ? (
            <div className="flex flex-row items-center ">
              <input
                autoFocus
                className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black outline-none w-44`}
                value={resumeData.rsLayout[rsIndex].title.toUpperCase()}
                onChange={(event: any) => {
                  resumeData.rsLayout[rsIndex].title = event.target.value;
                  setResumeData({
                    ...resumeData,
                  });
                }}
                onBlur={(event: any) => {
                  if (event.target.value.trim() === "") {
                    resumeData.rsLayout[rsIndex].title = "Organizations";
                    // getData.rsLayout[rsIndex].title;
                    setResumeData({
                      ...resumeData,
                    });
                  }
                }}
              />
              {inputPosition === "isedit-org" && (
                <div
                  onClick={() => {
                    resumeData.rsLayout[rsIndex].record.organizations.push(
                      addOrg(
                        resumeData.rsLayout[rsIndex].record.organizations.length
                      )
                    );
                    // console.log(resumeData.rsLayout[rsIndex].record);
                    setResumeData({ ...resumeData });
                  }}
                >
                  <i
                    className={` ml-8 fa-solid fa-circle-plus cursor-pointer text-xl ${theme.primary_fg()}`}
                  ></i>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-row items-center ">
              <div
                className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black`}
                onClick={(event) => {
                  event.stopPropagation();
                  // console.log("hello");
                  setInputPosition("isedit-org");
                }}
              >
                {orgData.title.toUpperCase()}
              </div>
            </div>
          )}

          {orgData.record.organizations.map((org: any, index: any) => {
            return (
              <div className="flex flex-col font-medium flex-wrap mt-3 ">
                {inputPosition === "isedit-org" + index ? (
                  <div className="flex">
                    <input
                      autoFocus
                      className="outline-none w-full text-sm bg-transparent"
                      value={
                        resumeData.rsLayout[rsIndex].record.organizations[index]
                      }
                      onChange={(event: any) => {
                        resumeData.rsLayout[rsIndex].record.organizations[
                          index
                        ] = event.target.value;
                        setResumeData({
                          ...resumeData,
                        });
                      }}
                      onBlur={(event: any) => {
                        if (event.target.value.trim() === "") {
                          resumeData.rsLayout[rsIndex].record.organizations[
                            index
                          ] = "organization";
                          // getData.rsLayout[rsIndex].record.organizations[
                          //   index
                          // ];
                          setResumeData({
                            ...resumeData,
                          });
                        }
                      }}
                    />
                    {inputPosition === "isedit-org" + index && index >= 1 && (
                      <i
                        className="fa-solid fa-trash mx-2 "
                        onClick={() => {
                          resumeData.rsLayout[
                            rsIndex
                          ].record.organizations.splice(index, 1);
                          setResumeData({ ...resumeData });
                        }}
                      ></i>
                    )}
                  </div>
                ) : (
                  <div
                    className={`mr-5 py-1 text-sm `}
                    onClick={(event) => {
                      event.stopPropagation();
                      setInputPosition("isedit-org" + index);
                    }}
                  >
                    {org}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div
      className={`w-full mt-10 md:w-5/12 md:ml-5 md:mt-0 ${font}`}
      onClick={() => {
        setInputPosition("");
      }}
    >
      {resumeData.rsLayout.map((rsData: any, rsIndex: any) => {
        return (
          <>
            {skillsComponent(rsData, rsIndex)}
            {orgComponent(rsData, rsIndex)}{" "}
          </>
        );
      })}
    </div>
  );
};

export default RightComponents;
