export const COUNTERDATA = "COUNTERDATA";
export const THEMECHANGER = "blue";
export const FONTCHANGE = "FONTCHANGE";
export const RESUMEDATA = "RESUMEDATA";

export function updateCounter(data: any) {
  return {
    type: COUNTERDATA,
    payload: data,
  };
}

export function updateTheme(data: any) {
  return {
    type: THEMECHANGER,
    payload: data,
  };
}
export function updateFont(data: any) {
  return {
    type: FONTCHANGE,
    payload: data,
  };
}
export function updateResumeData(data: any) {
  return {
    type: RESUMEDATA,
    payload: data,
  };
}
