import { useNavigate } from "react-router";
import { useTheme } from "../customHooks/useTheme";

const Downloaded = () => {
  const navigate = useNavigate();
  const [theme] = useTheme();
  return (
    <>
      <div className="flex flex-col bg-slate-300 h-screen justify-center items-center    ">
        <div className=" text-3xl font-thin text-zinc-600 ">
          File Downloaded
        </div>
        <div>
          <div
            className={`mt-7 px-4 py-2  text-lg rounded ${theme.bg()} ${theme.secondary_fg()}`}
            onClick={() => navigate("/")}
          >
            Go Back
          </div>
        </div>
      </div>
    </>
  );
};

export default Downloaded;
