import { useState } from "react";
import { useFont } from "../customHooks/useFont";
import { useResumeData } from "../customHooks/useResumeData";
import { useTheme } from "../customHooks/useTheme";
import { getResumeData } from "../redux/reducer";

const LeftComponents = () => {
  const [resumeData, setResumeData] = useResumeData();
  const [inputPosition, setInputPosition] = useState("");
  // const getData = useSelector((state: any) => state.getData);
  //console.log(getData.lsLayout);
  const [theme] = useTheme();
  const [font] = useFont();

  const addExpiernce = (i: number) => {
    return {
      role: `Position ${i + 1}`,
      company: `Company ${i + 1}`,
      location: "City, Country",
      date: "MM/YYYY-MM/YYYY",
      responsibilities: [
        "Develop high-quality software design and architecture",
        "Identify, prioritize and execute tasks in the software development life cycle",
        "Develop tools and applications by producing clean, efficient code",
      ],
    };
  };
  const addEducation = (i: number) => {
    return {
      degree: `Degree ${i + 1}`,
      university: `University ${i + 1}`,
      date: "MM/YYYY-MM/YYYY",
    };
  };
  const addLanguage = (i: number) => {
    return {
      lang: `Language ${i + 1}`,
      rate: 1,
    };
  };

  const wrkExpComponent = (lsIndex: any, expData: any) => {
    if (expData.type === "exp") {
      return (
        <>
          <div className="w-full">
            <>
              {inputPosition === "isedit-expTitle" ? (
                <div className="flex flex-row items-center ">
                  <input
                    autoFocus
                    className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black outline-none w-44`}
                    value={resumeData.lsLayout[lsIndex].title.toUpperCase()}
                    onChange={(event: any) => {
                      resumeData.lsLayout[lsIndex].title = event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event: any) => {
                      if (event.target.value.trim() === "") {
                        resumeData.lsLayout[lsIndex].title =
                          getResumeData.lsLayout[0].title;
                        // getResumeData.lsLayout[lsIndex].title;
                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                  />
                  {inputPosition === "isedit-expTitle" && (
                    <div
                      onClick={() => {
                        resumeData.lsLayout[lsIndex].record.push(
                          addExpiernce(
                            resumeData.lsLayout[lsIndex].record.length
                          )
                        );
                        setResumeData({ ...resumeData });
                      }}
                    >
                      <i
                        className={` ml-8 fa-solid fa-circle-plus cursor-pointer text-xl ${theme.primary_fg()}`}
                      ></i>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-row items-center ">
                  <div
                    className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black`}
                    onClick={(event) => {
                      event.stopPropagation();
                      // console.log("hello");
                      setInputPosition("isedit-expTitle");
                    }}
                  >
                    {expData.title.toUpperCase()}
                  </div>
                </div>
              )}
            </>

            {expData.record.map((rec: any, index: number) => {
              return (
                <div>
                  {inputPosition === "isedit-role" + index ? (
                    <div className="flex  mt-2 items-center">
                      <input
                        autoFocus
                        className="font-semibold outline-none"
                        value={resumeData.lsLayout[lsIndex].record[index].role}
                        onChange={(event: any) => {
                          resumeData.lsLayout[lsIndex].record[index].role =
                            event.target.value;
                          setResumeData({
                            ...resumeData,
                          });
                        }}
                        onBlur={(event: any) => {
                          if (event.target.value.trim() === "") {
                            resumeData.lsLayout[lsIndex].record[index].role =
                              getResumeData.lsLayout[0].record[0].role;
                            // ("Position");

                            // console.log(
                            //   getResumeData.lsLayout[lsIndex].record[0]
                            // );
                            setResumeData({
                              ...resumeData,
                            });
                          }
                        }}
                      />

                      {inputPosition === "isedit-role" + index &&
                        index >= 1 && (
                          <div
                            className="ml-10 text-sm"
                            onClick={(event) => {
                              event.stopPropagation();
                              // console.log(resumeData.lsLayout[0].record[index]);
                              resumeData.lsLayout[lsIndex].record.splice(
                                index,
                                1
                              );
                              setResumeData({ ...resumeData });
                            }}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </div>
                        )}
                    </div>
                  ) : (
                    <div
                      className="font-semibold mt-2"
                      onClick={(event) => {
                        event.stopPropagation();
                        setInputPosition("isedit-role" + index);
                      }}
                    >
                      {rec.role}
                    </div>
                  )}

                  {inputPosition === "isedit-company" + index ? (
                    <input
                      autoFocus
                      className="text-sm font-medium outline-none"
                      value={resumeData.lsLayout[lsIndex].record[index].company}
                      onChange={(event: any) => {
                        resumeData.lsLayout[lsIndex].record[index].company =
                          event.target.value;
                        setResumeData({
                          ...resumeData,
                        });
                      }}
                      onBlur={(event: any) => {
                        if (event.target.value === "") {
                          resumeData.lsLayout[lsIndex].record[index].company =
                            "Company";
                          //getData.lsLayout[lsIndex].record[index].company;
                          setResumeData({
                            ...resumeData,
                          });
                        }
                      }}
                    />
                  ) : (
                    <div
                      className="text-sm font-medium"
                      onClick={(event) => {
                        event.stopPropagation();
                        setInputPosition("isedit-company" + index);
                      }}
                    >
                      {rec.company}
                    </div>
                  )}
                  <div className="mt-1 flex flex-row flex-wrap justify-between italic text-sm text-gray-400">
                    {inputPosition === "isedit-date" + index ? (
                      <input
                        autoFocus
                        className="text-xs outline-none"
                        value={resumeData.lsLayout[lsIndex].record[index].date}
                        onChange={(event: any) => {
                          resumeData.lsLayout[lsIndex].record[index].date =
                            event.target.value;
                          setResumeData({
                            ...resumeData,
                          });
                        }}
                        onBlur={(event: any) => {
                          if (event.target.value === "") {
                            resumeData.lsLayout[lsIndex].record[index].date =
                              "MM/YYYY-MM/YYYY";

                            // getData.lsLayout[lsIndex].record[index].date;
                            setResumeData({
                              ...resumeData,
                            });
                          }
                        }}
                      />
                    ) : (
                      <div
                        className="text-xs"
                        onClick={(event) => {
                          event.stopPropagation();
                          setInputPosition("isedit-date" + index);
                        }}
                      >
                        {rec.date}
                      </div>
                    )}
                    {inputPosition === "isedit-location" + index ? (
                      <input
                        autoFocus
                        className="text-xs text-right outline-none"
                        value={resumeData.lsLayout[0].record[index].location}
                        onChange={(event: any) => {
                          resumeData.lsLayout[lsIndex].record[index].location =
                            event.target.value;
                          setResumeData({
                            ...resumeData,
                          });
                        }}
                        onBlur={(event: any) => {
                          if (event.target.value === "") {
                            resumeData.lsLayout[lsIndex].record[
                              index
                            ].location = "City, Country";
                            // getData.lsLayout[lsIndex].record[index].location;
                            setResumeData({
                              ...resumeData,
                            });
                          }
                        }}
                      />
                    ) : (
                      <div
                        className="text-xs"
                        onClick={(event) => {
                          event.stopPropagation();
                          setInputPosition("isedit-location" + index);
                        }}
                      >
                        {rec.location}
                      </div>
                    )}
                  </div>
                  <div>
                    {rec.responsibilities.map(
                      (resPoints: any, resindex: any) => {
                        return (
                          <div className="flex flex-row text-sm mt-3 ">
                            <div
                              className={`${theme.primary_fg()} text-base mr-2`}
                            >
                              -
                            </div>
                            {inputPosition ===
                            "isedit-responsibilities" + index + resindex ? (
                              <>
                                <input
                                  autoFocus
                                  className="outline-none w-full"
                                  value={
                                    resumeData.lsLayout[lsIndex].record[index]
                                      .responsibilities[resindex]
                                  }
                                  onChange={(event: any) => {
                                    resumeData.lsLayout[lsIndex].record[
                                      index
                                    ].responsibilities[resindex] =
                                      event.target.value;
                                    setResumeData({
                                      ...resumeData,
                                    });
                                  }}
                                  onBlur={(event: any) => {
                                    if (event.target.value === "") {
                                      resumeData.lsLayout[lsIndex].record[
                                        index
                                      ].responsibilities[resindex] =
                                        "responsibilities";
                                      // getData.lsLayout[lsIndex].record[
                                      //   index
                                      // ].responsibilities[resindex];
                                      setResumeData({
                                        ...resumeData,
                                      });
                                    }
                                  }}
                                />
                                {inputPosition ===
                                  "isedit-responsibilities" +
                                    index +
                                    resindex && (
                                  <div className="flex flex-row ml-10 ">
                                    <i
                                      className={`fa-solid fa-circle-plus mx-2 cursor-pointer`}
                                      onClick={() => {
                                        // console.log(resindex);
                                        resumeData.lsLayout[lsIndex].record[
                                          index
                                        ].responsibilities.splice(
                                          resindex,
                                          0,
                                          "heelo"
                                          // getData.lsLayout[lsIndex].record[
                                          //   index
                                          // ].responsibilities[0]
                                        );
                                        setResumeData({ ...resumeData });
                                      }}
                                    ></i>
                                    {resindex >= 1 && (
                                      <i
                                        className="fa-solid fa-trash mx-2 "
                                        onClick={() => {
                                          resumeData.lsLayout[lsIndex].record[
                                            index
                                          ].responsibilities.splice(
                                            resindex,
                                            1
                                          );
                                          setResumeData({ ...resumeData });
                                        }}
                                      ></i>
                                    )}
                                  </div>
                                )}
                              </>
                            ) : (
                              <div
                                className="text-sm"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setInputPosition(
                                    "isedit-responsibilities" + index + resindex
                                  );
                                }}
                              >
                                {resPoints}
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    }
  };

  const educationComponent = (lsIndex: any, edData: any) => {
    if (edData.type === "ed") {
      return (
        <>
          <div className="w-full mt-8">
            <div className="flex flex-row items-center ">
              {inputPosition === "isedit-eduTitle" ? (
                <>
                  <input
                    autoFocus
                    className={`border-y-2 ${theme.primary_fg()} text-lg  font-bold border-black outline-none w-28`}
                    value={resumeData.lsLayout[lsIndex].title.toUpperCase()}
                    onChange={(event: any) => {
                      resumeData.lsLayout[lsIndex].title = event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event: any) => {
                      if (event.target.value === "") {
                        resumeData.lsLayout[lsIndex].title = "Education";
                        // getData.lsLayout[lsIndex].title;
                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                  />
                  <i
                    className={` ml-8 fa-solid fa-circle-plus cursor-pointer text-xl ${theme.primary_fg()}`}
                    onClick={() => {
                      resumeData.lsLayout[lsIndex].record.push(
                        addEducation(resumeData.lsLayout[lsIndex].record.length)
                      );
                      setResumeData({ ...resumeData });
                    }}
                  ></i>
                </>
              ) : (
                <div
                  className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setInputPosition("isedit-eduTitle");
                  }}
                >
                  {resumeData.lsLayout[lsIndex].title.toUpperCase()}
                </div>
              )}
            </div>
            {edData.record.map((rec: any, index: number) => {
              return (
                <>
                  {inputPosition === "isedit-degree" + index ? (
                    <>
                      <div className="flex flex-row mt-2 items-center">
                        <input
                          autoFocus
                          className="font-semibold outline-none"
                          value={
                            resumeData.lsLayout[lsIndex].record[index].degree
                          }
                          onChange={(event: any) => {
                            resumeData.lsLayout[lsIndex].record[index].degree =
                              event.target.value;
                            setResumeData({
                              ...resumeData,
                            });
                          }}
                          onBlur={(event: any) => {
                            if (event.target.value === "") {
                              resumeData.lsLayout[lsIndex].record[
                                index
                              ].degree = "Degree";
                              // getData.lsLayout[lsIndex].record[index].degree;
                              setResumeData({
                                ...resumeData,
                              });
                            }
                          }}
                        />

                        {index >= 1 && (
                          <div className="ml-10 text-sm">
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => {
                                // console.log(resumeData.lsLayout[1].record[index]);
                                resumeData.lsLayout[1].record.splice(index, 1);
                                setResumeData({ ...resumeData });
                              }}
                            ></i>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      className="font-semibold mt-2"
                      onClick={(event) => {
                        event.stopPropagation();
                        setInputPosition("isedit-degree" + index);
                      }}
                    >
                      {rec.degree}
                    </div>
                  )}

                  {inputPosition === "isedit-university" + index ? (
                    <input
                      autoFocus
                      className="text-sm font-medium outline-none"
                      value={
                        resumeData.lsLayout[lsIndex].record[index].university
                      }
                      onChange={(event: any) => {
                        resumeData.lsLayout[lsIndex].record[index].university =
                          event.target.value;
                        setResumeData({
                          ...resumeData,
                        });
                      }}
                      onBlur={(event: any) => {
                        if (event.target.value === "") {
                          resumeData.lsLayout[lsIndex].record[
                            index
                          ].university = "University";
                          // getData.lsLayout[lsIndex].record[index].university;
                          setResumeData({
                            ...resumeData,
                          });
                        }
                      }}
                    />
                  ) : (
                    <div
                      className="text-sm font-medium"
                      onClick={(event) => {
                        event.stopPropagation();
                        setInputPosition("isedit-university" + index);
                      }}
                    >
                      {rec.university}
                    </div>
                  )}
                  <div className="mt-1 italic text-sm text-gray-400">
                    {inputPosition === "isedit-eduDate" + index ? (
                      <input
                        autoFocus
                        className="text-xs outline-none"
                        value={resumeData.lsLayout[lsIndex].record[index].date}
                        onChange={(event: any) => {
                          resumeData.lsLayout[lsIndex].record[index].date =
                            event.target.value;
                          setResumeData({
                            ...resumeData,
                          });
                        }}
                        onBlur={(event: any) => {
                          if (event.target.value === "") {
                            resumeData.lsLayout[lsIndex].record[index].date =
                              "MM/YYYY-MM/YYYY";

                            //  getData.lsLayout[lsIndex].record[index].date;
                            setResumeData({
                              ...resumeData,
                            });
                          }
                        }}
                      />
                    ) : (
                      <div
                        className="text-xs"
                        onClick={(event) => {
                          event.stopPropagation();
                          setInputPosition("isedit-eduDate" + index);
                        }}
                      >
                        {rec.date}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </>
      );
    }
  };

  const languageComponent = (lsIndex: any, langData: any) => {
    if (langData.type === "lang") {
      return (
        <>
          <div className="w-full mt-8 ">
            <div className="flex flex-row items-center ">
              {inputPosition === "isedit-lngTitle" ? (
                <>
                  <input
                    autoFocus
                    className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black outline-none w-28`}
                    value={resumeData.lsLayout[lsIndex].title.toUpperCase()}
                    onChange={(event: any) => {
                      resumeData.lsLayout[lsIndex].title = event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event: any) => {
                      if (event.target.value === "") {
                        resumeData.lsLayout[lsIndex].title = "Languages";

                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                  />
                  <i
                    className={` ml-8 fa-solid fa-circle-plus cursor-pointer text-xl ${theme.primary_fg()}`}
                    onClick={() => {
                      resumeData.lsLayout[lsIndex].record.push(
                        addLanguage(resumeData.lsLayout[lsIndex].record.length)
                      );
                      setResumeData({ ...resumeData });
                    }}
                  ></i>
                </>
              ) : (
                <div
                  className={`border-y-2 ${theme.primary_fg()} text-lg font-bold border-black`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setInputPosition("isedit-lngTitle");
                  }}
                >
                  {resumeData.lsLayout[lsIndex].title.toUpperCase()}
                </div>
              )}
            </div>
          </div>
          {langData.record.map((rec: any, index: number) => {
            return (
              <>
                <div className="flex flex-wrap  text-sm justify-start items-center mt-3">
                  {inputPosition === "isedit-language" + index ? (
                    <>
                      <input
                        autoFocus
                        className=" w-24 outline-none mb-1"
                        value={resumeData.lsLayout[lsIndex].record[index].lang}
                        onChange={(event: any) => {
                          resumeData.lsLayout[lsIndex].record[index].lang =
                            event.target.value;
                          setResumeData({
                            ...resumeData,
                          });
                        }}
                        onBlur={(event: any) => {
                          if (event.target.value === "") {
                            resumeData.lsLayout[lsIndex].record[index].lang =
                              "Language";

                            setResumeData({
                              ...resumeData,
                            });
                          }
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="mr-3 mb-1 w-24"
                        onClick={(event) => {
                          event.stopPropagation();
                          setInputPosition("isedit-language" + index);
                        }}
                      >
                        <div>{rec.lang}</div>
                      </div>
                    </>
                  )}
                  <div className="flex flex-row items-center">
                    <div className="flex items-center ">
                      {[...Array(5)].map((num, i) => {
                        return (
                          <div
                            className={`${
                              rec.rate <= i ? "bg-transparent" : theme.bg()
                            }  h-3 w-3 m-2 rounded-full border-2 cursor-pointer ${theme.border()} `}
                            onClick={(event) => {
                              event.stopPropagation();
                              resumeData.lsLayout[lsIndex].record[index].rate =
                                i + 1;
                              setResumeData({ ...resumeData });
                            }}
                          ></div>
                        );
                      })}
                    </div>
                    {inputPosition === "isedit-language" + index &&
                      index >= 1 && (
                        <div className="ml-10 text-sm">
                          <i
                            className="fa-solid fa-trash"
                            onClick={() => {
                              // console.log(resumeData.lsLayout[0].record[index]);
                              resumeData.lsLayout[lsIndex].record.splice(
                                index,
                                1
                              );
                              setResumeData({ ...resumeData });
                              setInputPosition("");
                            }}
                          ></i>
                        </div>
                      )}
                  </div>
                </div>
              </>
            );
          })}
        </>
      );
    }
  };
  return (
    <div
      className={`w-full md:w-6/12 ${font}`}
      onClick={() => {
        setInputPosition("");
      }}
    >
      {resumeData.lsLayout.map((lsData: any, lsIndex: any) => {
        return (
          <>
            {wrkExpComponent(lsIndex, lsData)}
            {educationComponent(lsIndex, lsData)}
            {languageComponent(lsIndex, lsData)}
          </>
        );
      })}
    </div>
  );
};

export default LeftComponents;
