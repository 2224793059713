import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateResumeData } from "../redux/actions";

export const useResumeData = (): any => {
  const dispatch = useDispatch();
  const getData = useSelector((state: any) => state.getData);

  const [resumeData, setResumeData] = useState(getData);

  useEffect(() => {
    dispatch(updateResumeData(resumeData));
  }, [dispatch, resumeData]);

  return [resumeData, setResumeData];
};
