import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { PDFDocument } from "./PDFDocument";
import ReactDOMServer from "react-dom/server";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DownloadJSON = () => {
  const getData = useSelector((state: any) => state.getData);
  const navigate = useNavigate();
  const exPdfMake = {
    content: [
      {
        text: `This is header `,
        style: "header",
      },
      { text: `${getData.name}`, style: "menu" },
    ],
    styles: {
      header: {
        fontSize: 30,
        fontWeight: 700,
        textAlign: "center",
        marginTop: 32,
      },
      menu: {
        textAlign: "center",
        marginRight: 32,
        color: "red",
      },
    },
  };
  const createPdf = () => {
    const pdfGenerator = pdfMake.createPdf(exPdfMake);
    pdfGenerator.download();
    navigate("/download");
  };
  const exportData = () => {
    console.log(getData);
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(getData)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${Date.now()}.json`;
    link.click();

    navigate("/download");
  };
  const downloadPDF = () => {
    const element = <PDFDocument data={getData} />;
    const html = ReactDOMServer.renderToString(element);
    const blob = new Blob([html], { type: "application/pdf" });
    saveAs(blob, "myResume.pdf");

    navigate("/download");
  };

  return (
    <>
      <div
        className="fixed flex bg-gray-300 bottom-24 right-6 w-12 h-12 p-4 rounded-full items-center transition-all ease-out duration-100"
        onClick={(event) => {
          event.stopPropagation();
          createPdf();
        }}
      >
        <i className="fa-solid fa-download"></i>
      </div>
    </>
  );
};

export default DownloadJSON;
// i have returning in console like this i want to convert and download in pdf format how can i do in react
