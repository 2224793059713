import { useState } from "react";
import { useTheme } from "../customHooks/useTheme";
import themeChangeColor from "../configs/themeChangeColor";
import { themes } from "../configs/themeList";
import { FontList } from "../configs/fontList";
import { useFont } from "../customHooks/useFont";
import { useSelector } from "react-redux";

const ThemeFontChange = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [, setTheme] = useTheme();
  const getTheme = useSelector((state: any) => state.themeColor);
  const getFont = useSelector((state: any) => state.getFont);
  const [, setFont] = useFont();

  return (
    <>
      {isClicked ? (
        <div className=" fixed flex flex-col bottom-4 right-4 bg-gray-300  w-64 h-96 transition-all ease-out duration-100 ">
          <div
            className="flex justify-end mt-4 mr-3"
            onClick={() => setIsClicked(false)}
          >
            <i className="fa-solid fa-xmark m-2 "></i>
          </div>
          <div className="mx-4 mt-4 ">
            <div className="text-lg font-bold ">Select Theme</div>
            <div className="flex flex-row justify-around bg-slate-200 p-2 rounded-md m-2">
              {themes.map((theme, index) => (
                <div
                  key={index}
                  className={`w-8 h-8 rounded-full ${themeChangeColor(
                    theme
                  ).bg()} border-2 ${
                    theme === getTheme ? "border-black" : ""
                  } cursor-pointer`}
                  onClick={() => {
                    setTheme(theme);
                  }}
                ></div>
              ))}
            </div>
          </div>

          <div className=" flex flex-col mx-4 mt-4 ">
            <div className="text-lg font-bold mb-2 ">Select Font</div>
            <div className="felx flex-wrap items-center">
              {FontList.map((font, index) => (
                <button
                  key={index}
                  className={`m-1 p-2 w-auto font-semibold  border-2 ${
                    font === getFont ? "border-slate-600" : "border-slate-50"
                  } bg-slate-200 rounded `}
                  onClick={() => {
                    setFont(font);
                  }}
                >
                  {font}
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="fixed flex bg-gray-300 bottom-6 right-6 w-12 h-12 p-4 rounded-full items-center transition-all ease-out duration-100"
          onClick={() => setIsClicked(true)}
        >
          <i className="fa-solid fa-brush"></i>
        </div>
      )}
    </>
  );
};

export default ThemeFontChange;
